<template>
  <v-dialog
    scrollable
    v-model="dialogEdit"
    persistent
    max-height="100%"
    max-width="100%"
  >
    <v-card>
      <v-card-title class="text-h5">
        <strong
          >({{ nuevoUsuario.alias }}) {{ nuevoUsuario.nombre_completo }}</strong
        >
      </v-card-title>
      <v-card-text>
        <v-row>
          <!-- stepper inicio -->
          <v-col>
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step step="1" editable>
                  Datos generales
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2" editable> Modulos </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3" editable>
                  Asignar supervisoras
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <!-- S T E P S -->
                <v-stepper-content step="1">
                  <v-card class="mb-12" color="" height="100%" elevation="0">
                    <v-card-text>
                      <!-- form new usuario , credenciales -->
                      <h4>
                        Actualizar las credenciales para el inicio de sesión
                      </h4>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="nuevoUsuario.alias"
                            label="Nombre de usuario"
                            required
                            @keydown="
                              $event.keyCode === 32
                                ? $event.preventDefault()
                                : false
                            "
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- form new usuario , credenciales -->
                      <!-- form new usuario , credenciales -->
                      <span>Datos generales</span>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="nuevoUsuario.codigo"
                            label="Código único de empleado"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="nuevoUsuario.nombre_completo"
                            label="Nombres y apellidos"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-btn
                            color="green"
                            dark
                            class="float-right"
                            @click="fnEditarUsuario"
                          >
                            Editar usuario
                          </v-btn>
                        </v-col>
                        <!-- 
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="lastname"
                              
                              label="Contraseña"
                              required
                            ></v-text-field>
                          </v-col> -->
                      </v-row>
                      <!-- form new usuario , credenciales -->
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                  <!-- FORMULARIO PARA LA EDICIÓNI DE LA CONTRASEÑA -->
                  <v-card class="mb-12" color="" height="100%" elevation="0">
                    <v-card-text>
                      <!-- form new usuario , credenciales -->
                      <span>Cambiar contraseña</span>
                      <p class="text--disabled">
                        Debes validar tu usuario antes de poder cambiar la
                        contraseña
                      </p>
                      <v-row align="center" justify="center">
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="
                              this.$store.state.auth.payload.usuario.alias
                            "
                            label="Usuario"
                            @keydown="$event.preventDefault()"
                            append-icon="mdi-account"
                            v-on:keyup.enter="(page = 1), fnFindUsuarios()"
                          ></v-text-field>
                          <v-text-field
                            :disabled="!flag_edit_password_disable"
                            v-model="varValidatePassword"
                            label="Escribe tu contraseña"
                            required
                            type="password"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            :rules="[rules.required, rules.counter]"
                            v-model="varNewPassword"
                            :disabled="flag_edit_password_disable"
                            :label="`Escribe la nueva contraseña para ${nuevoUsuario.alias}`"
                            required
                            @keydown="
                              $event.keyCode === 32
                                ? $event.preventDefault()
                                : false
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-btn
                            x-small
                            v-if="flag_edit_password_disable"
                            color="yellow"
                            class="float-end"
                            @click="fnValidarCredenciales"
                            :title="flag_edit_password_btn_title"
                          >
                            {{ flag_edit_password_btn_text }}
                          </v-btn>
                          <v-btn
                            x-small
                            v-else
                            color="green"
                            dark
                            class="float-end"
                            @click="fnUpdateUserPassword"
                            :title="flag_edit_password_btn_title"
                          >
                            {{ flag_edit_password_btn_text }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-switch v-model="switchMe" @change="fnSwitchMe()">
                        <template v-slot:label>
                          <span v-if="switchMe == true">
                            Super usuario (sin restricciones)
                          </span>
                          <span v-else>
                            Usuario administrativo ( con accesos restringidos)
                          </span>
                        </template>
                      </v-switch>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                  <!-- FIN FORMULARIO PARA LA EDICIÓNI DE LA CONTRASEÑA -->
                  <!-- <v-btn text> Cancel </v-btn> -->
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-card
                    class="mb-12 card-modulos"
                    color=""
                    height="100%"
                    elevation="0"
                  >
                    <v-card-text class="card-text-modulos">
                      <!-- inicio listado de modulos -->
                      <listaDeModulos
                        :prop_selected_ids="prop_selected_ids_modulos"
                        @modulosUpdate="fnUpdateNewModulos"
                      />
                      <!-- fin listado de modulos -->
                    </v-card-text>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <!-- inicio listado supervisoras -->
                  <listadoSupervisoras
                    :prop_selected_ids="prop_selected_ids_supervisoras"
                    @supervisorasUpdate="fnUpdateNewSupervisoras"
                  />
                  <!-- fin listado supervisoras -->
                </v-stepper-content>
                <!-- S T E P S -->
              </v-stepper-items>
            </v-stepper>
          </v-col>
          <!-- stepper fin -->
          <!-- RESUMEN  NUEVO USUARIO  ADMINISTRATIVO -->
          <v-col>
            <v-card>
              <v-card-title primary-title>
                Resumen de nuevo usuario
              </v-card-title>
              <v-card-text>
                <div>Usuario: {{ nuevoUsuario.alias }}</div>

                <div>Código: {{ nuevoUsuario.codigo }}</div>
                <div>Nombre: {{ nuevoUsuario.nombre_completo }}</div>
                <div></div>
                <v-spacer></v-spacer>
                <div v-if="switchMe == false">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Módulos</v-list-item-subtitle>
                      <v-list-item-title
                        v-for="modulo in nuevoUsuarioAdministradorModulos"
                        :key="modulo.id"
                        >{{ modulo.titulo }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <v-spacer></v-spacer>
                <div v-if="switchMe == false">
                  <p>
                    Listado de supervisoras asignadas (total:
                    {{ nuevoUsuarioAdministradorSupervisoras.length }})
                  </p>
                  <table class="table">
                    <thead>
                      <tr>
                        <td>Usuario / nombre</td>
                        <td>Agente de comercio</td>
                        <td>División</td>
                        <td>Región</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="supervisora in nuevoUsuarioAdministradorSupervisoras"
                        :key="supervisora.id"
                      >
                        <td class="td_titulo">
                          {{ supervisora.alias }} /
                          {{ supervisora.nombre_completo }}
                        </td>
                        <td class="td_titulo">{{ supervisora.tipo }}</td>
                        <td class="td_titulo">{{ supervisora.division }}</td>
                        <td class="td_titulo">{{ supervisora.region }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- RESUMEN  NUEVO USUARIO  ADMINISTRATIVO -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="e1 > 2 || switchMe == !false"
          color="primary"
          @click="fnNextStep()"
        >
          siguiente
        </v-btn>

        <v-btn :disabled="e1 == 1" text @click="fnBackStep()"> Atras </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          text
          dark
          class="float-right"
          @click="fndialogEdit"
        >
          Cerrar editor de usuario
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from 'moment';
require('moment/locale/es');
import { mapActions } from 'vuex';
import listaDeModulos from './usuariosGestionModulos.vue';
import listadoSupervisoras from './usuariosGestionSupervisoras.vue';
export default {
  name: 'dialog_editar_administrativo',
  components: {
    listaDeModulos,
    listadoSupervisoras,
  },
  props: ['dialogEdit', 'flag_editar_usuario'],
  computed: {},
  data() {
    return {
      switchMe: null,
      loading_flag_switchMe: false,
      //rules
      rules: {
        required: (value) => !!value || 'El campo es obligatorio.',
        counter: (value) => value.length >= 5 || 'Minimo 5 caracteres',
      },
      //cambiar contraseña
      varValidatePassword: '',
      varNewPassword: '',
      flag_edit_password_disable: true,
      flag_edit_password_btn_text: 'VALIDA TU CONTRASEÑA',
      flag_edit_password_btn_title:
        'Valida tu usuario para poder cambiar la contraseña',
      //las variables para cambiar contraseña son modificadas en la función: fnValidarCredenciales
      //
      local_flag_editor_mode: false,
      prop_selected_ids_supervisoras: [],
      prop_selected_ids_modulos: [],
      //
      currentUsuario: null,
      nuevoUsuario: {
        alias: '',
        codigo: '',
        password: '',
        nombre_completo: '',
      },
      nuevoUsuarioAdministradorModulos: [],
      nuevoUsuarioAdministradorSupervisoras: [],
      //
      e1: 1,
      //
    };
  },
  watch: {
    flag_editar_usuario(newValue) {
      if (newValue != null) {
        this.local_flag_editor_mode = true;
        //captura el id del usuario que se desea modificar
        //generacion de informacion para actualizar

        this.getUsuarios(newValue).then((result) => {
          if (result.sesion_rol == 0) {
            console.log('este usuario es super usuario');
            this.switchMe = true;
          } else {
            console.log('este usuario es simple administrativo');
            this.switchMe = false;
          }
          this.currentUsuario = newValue;
          this.nuevoUsuario.alias = result.alias;
          this.nuevoUsuario.codigo = result.codigo;
          this.nuevoUsuario.nombre_completo = result.nombre_completo;
        });
        //
        this.findAdministrativoSupervisoras({
          query: {
            $limit: 250,
            id_usuario_administrativo: newValue,
          },
        }).then((result) => {
          console.log('sus supervisoras');
          console.log(result);
          this.prop_selected_ids_supervisoras = result.data.map(
            (a) => a.id_usuario_supervisora
          );
        });
        //
        this.findAdministrativoModulos({
          query: {
            id_usuario_administrativo: newValue,
          },
        }).then((result) => {
          this.prop_selected_ids_modulos = result.data.map((a) => a.id_modulo);
        });
      }
    },
    nuevoUsuarioAdministradorModulos(newValue, oldValue) {
      /**
       *
       * se elimna un modulo asignado al usuario
       */
      let elEliminado = oldValue.filter(
        (o) => !newValue.some((i) => i.id === o.id)
      );
      if (elEliminado.length > 0 && this.local_flag_editor_mode) {
        this.removeAdministrativoModulos([
          null,
          {
            query: {
              id_usuario_administrativo: this.currentUsuario,
              id_modulo: elEliminado[0].id,
            },
          },
        ]).then(() => {
          this.$notify({
            type: 'warning',
            group: 'foo',
            title: `Acceso a modulos`,
            text: `Se retiro el acceso al modulo ${elEliminado[0].titulo}`,
          });
        });
      }
      /**
       *
       * se agrega un modulo al usuario administrativo
       */
      let elAgregado = newValue.filter(
        (o) => !oldValue.some((i) => i.id === o.id)
      );
      if (
        elAgregado.length > 0 &&
        this.local_flag_editor_mode &&
        this.currentUsuario &&
        elAgregado[0].id
      ) {
        this.createAdministrativoModulos({
          id_usuario_administrativo: this.currentUsuario,
          id_modulo: elAgregado[0].id,
        }).then(() => {
          this.$notify({
            type: 'success',
            group: 'foo',
            title: `Acceso a modulos`,
            text: `Se agrego el acceso al modulo ${elAgregado[0].titulo}`,
          });
        });
      }
    },
    nuevoUsuarioAdministradorSupervisoras(newValue, oldValue) {
      /**
       *
       * se elimna un modulo asignado al usuario
       */
      let elEliminado = oldValue.filter(
        (o) => !newValue.some((i) => i.id === o.id)
      );
      if (elEliminado.length > 0 && this.local_flag_editor_mode) {
        this.removeAdministrativoSupervisoras([
          null,
          {
            query: {
              id_usuario_administrativo: this.currentUsuario,
              id_usuario_supervisora: elEliminado[0].id,
            },
          },
        ]).then(() => {
          this.$notify({
            type: 'warning',
            group: 'foo',
            title: `Seguimiento a supervisora`,
            text: `Se retiro el acceso a la información del usuario:  ${elEliminado[0].nombre_completo}`,
          });
        });
      }
      /**
       *
       * se agrega un modulo al usuario administrativo
       */
      let elAgregado = newValue.filter(
        (o) => !oldValue.some((i) => i.id === o.id)
      );
      if (elAgregado.length > 0 && this.local_flag_editor_mode) {
        this.createAdministrativoSupervisoras({
          id_usuario_administrativo: this.currentUsuario,
          id_usuario_supervisora: elAgregado[0].id,
        }).then(() => {
          this.$notify({
            type: 'success',
            group: 'foo',
            title: `Seguimiento a supervisora`,
            text: `Se concedió el acceso a la información del usuario:  ${elAgregado[0].nombre_completo}`,
          });
        });
      }
    },
  },
  methods: {
    //modal steps vars
    fnNextStep() {
      this.e1 = this.e1 + 1;
    },
    fnBackStep() {
      this.e1 = this.e1 - 1;
    },
    /** API FEATHERS */
    ...mapActions('Usuarios', {
      getUsuarios: 'get',
      findUsuarios: 'find',
      patchUsuario: 'patch',
      crearUsuario: 'create',
    }),
    ...mapActions('AdministrativoSupervisoras', {
      getAdministrativoSupervisoras: 'get',
      findAdministrativoSupervisoras: 'find',
      patchAdministrativoSupervisoras: 'patch',
      createAdministrativoSupervisoras: 'create',
      removeAdministrativoSupervisoras: 'remove',
    }),
    ...mapActions('AdministrativoModulos', {
      getAdministrativoModulos: 'get',
      findAdministrativoModulos: 'find',
      patchAdministrativoModulos: 'patch',
      createAdministrativoModulos: 'create',
      removeAdministrativoModulos: 'remove',
    }),
    //general funcs
    getDate(date) {
      return date ? moment(date).format('DD MMMM YYYY') : '';
    },
    getColor(calories) {
      if (calories > 400) return 'red';
      else if (calories > 200) return 'orange';
      else return 'green';
    },
    //funciones para traer informacion de modulos y usuarios
    fnUpdateNewModulos(value) {
      //actualiza el listado de modulos seleccionados para el usuario
      this.nuevoUsuarioAdministradorModulos = value;
    },
    fnUpdateNewSupervisoras(value) {
      this.nuevoUsuarioAdministradorSupervisoras = value;
    },
    fnEditarUsuario() {
      this.$store.state.globalLoading = true;
      /** crear usuairo */
      this.patchUsuario([this.currentUsuario, this.nuevoUsuario, {}])
        .then(() => {
          //   this.fnEditarUsuarioModulos(result.id);
          //   this.fnEditarUsuarioSupervisoras(result.id);
          this.$store.state.globalLoading = false;
          this.$notify({
            type: 'success',
            group: 'foo',
            title: 'Usuario editado',
            text: 'Se ha editado el usuario',
          });
        })
        .catch((e) => {
          this.$store.state.globalLoading = false;
          this.$notify({
            type: 'error',
            group: 'foo',
            title: 'Error al crear usuario',
            text: e.message,
          });
        });
    },
    fnCrearUsuarioModulos(id_usuario) {
      let arregloDeModulosParaElUsuario = [];
      this.nuevoUsuarioAdministradorModulos.forEach((modulo) => {
        arregloDeModulosParaElUsuario.push({
          id_usuario_administrativo: id_usuario,
          id_modulo: modulo.id,
        });
      });

      this.createAdministrativoModulos([arregloDeModulosParaElUsuario, {}])
        .then(() => {
          this.$notify({
            type: 'success',
            group: 'foo',
            title: 'Asignación de modulos',
            text: 'Se han asignado los modulos al usuario.',
          });
        })
        .catch((e) => {
          this.$store.state.globalLoading = false;
          this.$notify({
            type: 'error',
            group: 'foo',
            title: 'Error al asignar modulos',
            text: e.message,
          });
        });
    },
    fnCrearUsuarioSupervisoras(id_usuario) {
      let arregloDeSupervisorasParaElUsuario = [];

      this.nuevoUsuarioAdministradorSupervisoras.forEach((supervisora) => {
        arregloDeSupervisorasParaElUsuario.push({
          id_usuario_administrativo: id_usuario,
          id_usuario_supervisora: supervisora.id,
        });
      });

      this.createAdministrativoSupervisoras([
        arregloDeSupervisorasParaElUsuario,
        {},
      ])
        .then(() => {
          this.$store.state.globalLoading = false;
          this.$notify({
            type: 'success',
            group: 'foo',
            title: 'Asignación de supervisoras',
            text: 'Se han asignado las supervisoras al usuario.',
          });
        })
        .catch((e) => {
          this.$store.state.globalLoading = false;
          this.$notify({
            type: 'error',
            group: 'foo',
            title: 'Error al asignar supervisoras',
            text: e.message,
          });
        });
    },
    fndialogEdit() {
      this.local_flag_editor_mode = false;
      this.nuevoUsuarioAdministradorModulos = [];
      this.nuevoUsuarioAdministradorSupervisoras = [];
      this.currentUsuario = null;
      this.$emit('fndialogEdit', false);
    },
    /**
     * validar contraseña
     *
     */
    fnValidarCredenciales() {
      this.$store
        .dispatch('auth/authenticate', {
          strategy: 'local',
          alias: this.$store.state.auth.payload.usuario.alias,
          password: this.varValidatePassword,
        })
        .then(() => {
          //
          this.flag_edit_password_disable = false;
          this.flag_edit_password_btn_title = `Puedes cambiarle la contraseña al usuario ${this.nuevoUsuario.nombre_completo}`;
          this.flag_edit_password_btn_text = 'Ingresa la nueva contraseña';
          //
          this.$notify({
            type: 'success',
            group: 'foo',
            title: `Usuario confirmado`,
            text: `Puedes cambiar la contraseña del usuario  ${this.nuevoUsuario.nombre_completo}`,
          });
        })
        .catch((e) => {
          console.error(e);
          this.$notify({
            type: 'error',
            group: 'foo',
            title: `ERROR`,
            text: `Tu usuario y contraseña no concuerdan, por favor intentalo de nuevo`,
          });
        });
    },
    fnUpdateUserPassword() {
      this.patchUsuario([
        this.currentUsuario,
        { password: this.varNewPassword },
        {},
      ]).then(() => {
        this.flag_edit_password_disable = !this.flag_edit_password_disable;
        this.flag_edit_password_btn_text = 'Valida tu contraseña';
        this.$notify({
          type: 'light-green darken-3',
          group: 'foo',
          title: 'Se han cambiado las credenciales al usuario ',
          text: 'Se ha editado el usuario',
        });
      });
    },
    fnSwitchMe() {
      console.log('Actualizando la información de: ' + this.currentUsuario);
      if (this.switchMe == true && this.currentUsuario) {
        this.loading_flag_switchMe = true;
        this.patchUsuario([this.currentUsuario, { sesion_rol: 0 }, {}])
          .then(() => {
            this.loading_flag_switchMe = false;
            //   this.fnEditarUsuarioModulos(result.id);
            //   this.fnEditarUsuarioSupervisoras(result.id);
            this.$notify({
              type: 'light-blue darken-3',
              group: 'foo',
              title: 'Se le han otorgado los permisos de super usuario',
              text: 'Se ha editado el usuario',
            });
          })
          .catch((e) => {
            this.loading_flag_switchMe = false;
            this.$store.state.globalLoading = false;
            this.$notify({
              type: 'error',
              group: 'foo',
              title: 'Error al modificar los permisos del usuario',
              text: e.message,
            });
          });

        console.log('to super user');
      } else {
        if (this.currentUsuario) {
          this.loading_flag_switchMe = true;
          this.patchUsuario([this.currentUsuario, { sesion_rol: 1 }, {}])
            .then(() => {
              this.loading_flag_switchMe = false;
              //   this.fnEditarUsuarioModulos(result.id);
              //   this.fnEditarUsuarioSupervisoras(result.id);
              this.$notify({
                type: 'cyan',
                group: 'foo',
                title: 'Se le han retirado los permisos de super usuario',
                text: 'Se ha editado el usuario',
              });
            })
            .catch((e) => {
              this.loading_flag_switchMe = false;
              this.$store.state.globalLoading = false;
              this.$notify({
                type: 'error',
                group: 'foo',
                title: 'Error al modificar los permisos del usuario',
                text: e.message,
              });
            });
        }

        console.log('to limited user ');
      }
    },
  },
};
</script>
<style scoped>
.td_titulo {
  font-weight: 700;
  color: black;
  margin-right: 15px;
}
.td_detalle {
  color: #424242;
}
</style>
