<template>
  <v-container class="" fluid>
    <v-row align="center" class="px-3 mx-auto">
      <!-- PARAMETROS DE BUSCADOR -->
      <v-col cols="4">
        <v-text-field
          type="search"
          v-on:keyup.enter="(page = 1), fnFindUsuarios()"
          v-model="searchSupervisora"
          title="Usuario"
          label="Alias, nombre"
          class="mx-4"
          append-icon="mdi-account"
          autocomplete="false"
        ></v-text-field>
      </v-col>

      <v-col cols="1">
        <v-btn
          small
          color="blue-grey"
          class="white--text"
          @click="(page = 1), fnFindUsuarios()"
        >
          Buscar
          <v-icon right dark> mdi-magnify </v-icon>
        </v-btn>
      </v-col>
      <!-- FIN PARAMETROS DE BUSCADOR -->
      <!-- Botón verde, activa modal de: crear nuevo usuario -->
      <v-col offset-md="5">
        <v-dialog
          scrollable
          v-model="dialogCreate"
          persistent
          max-height="100%"
          max-width="100%"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green"
              class="float-right"
              small
              dark
              v-bind="attrs"
              v-on="on"
            >
              Crear nuevo usuario
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Formulario para creación de usuarios
            </v-card-title>
            <v-card-text>
              <v-row>
                <!-- stepper inicio -->
                <v-col>
                  <v-stepper v-model="e1">
                    <v-stepper-header>
                      <v-stepper-step :complete="e1 > 1" step="1">
                        Datos generales
                      </v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step :complete="e1 > 2" step="2">
                        Modulos
                      </v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step step="3">
                        Asignar supervisoras
                      </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <!-- S T E P S -->
                      <v-stepper-content step="1">
                        <v-card
                          class="mb-12"
                          color=""
                          height="100%"
                          elevation="0"
                        >
                          <v-card-text>
                            <!-- form new usuario , credenciales -->
                            <h4>Ingrese credenciales para inicio de sesión:</h4>
                            <v-row>
                              <v-col cols="12" md="4">
                                <v-text-field
                                  ref="nombre_usuario"
                                  v-model="nuevoUsuario.alias"
                                  :counter="30"
                                  label="Nombre de usuario"
                                  required
                                  @keydown="
                                    $event.keyCode === 32
                                      ? $event.preventDefault()
                                      : false
                                  "
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" md="4">
                                <v-text-field
                                  ref="password"
                                  v-model="nuevoUsuario.password"
                                  :counter="30"
                                  label="Contraseña"
                                  required
                                  @keydown="
                                    $event.keyCode === 32
                                      ? $event.preventDefault()
                                      : false
                                  "
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <!-- form new usuario , credenciales -->
                            <!-- form new usuario , credenciales -->
                            <span>Datos generales</span>
                            <v-row>
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="nuevoUsuario.codigo"
                                  :counter="30"
                                  label="Código único de empleado (opcional)"
                                  title="Código único de empleado (opcional)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="nuevoUsuario.nombre_completo"
                                  :counter="30"
                                  label="Nombres y apellidos"
                                  required
                                ></v-text-field>
                              </v-col>
                              <!-- 
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="lastname"
                              :counter="30"
                              label="Contraseña"
                              required
                            ></v-text-field>
                          </v-col> -->
                            </v-row>
                            <!-- form new usuario , credenciales -->
                          </v-card-text>
                          <v-card-actions>
                            <v-switch
                              v-model="switchMe"
                              @change="nuevoUsuario.sesion_rol = switchMe"
                            >
                              <template v-slot:label>
                                <span v-if="switchMe == true">
                                  Super usuario (sin restricciones)
                                </span>
                                <span v-else>
                                  Usuario administrativo ( con accesos
                                  restringidos)
                                </span>
                              </template>
                            </v-switch>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>

                        <!-- <v-btn text> Cancel </v-btn> -->
                      </v-stepper-content>

                      <v-stepper-content step="2">
                        <v-card
                          class="mb-12 card-modulos"
                          color=""
                          height="100%"
                          elevation="0"
                        >
                          <v-card-text class="card-text-modulos">
                            <!-- inicio listado de modulos -->
                            <listaDeModulos
                              @modulosUpdate="fnUpdateNewModulos"
                            />
                            <!-- fin listado de modulos -->
                          </v-card-text>
                        </v-card>
                      </v-stepper-content>

                      <v-stepper-content step="3">
                        <!-- inicio listado supervisoras -->
                        <listadoSupervisoras
                          @supervisorasUpdate="fnUpdateNewSupervisoras"
                        />
                        <!-- fin listado supervisoras -->
                      </v-stepper-content>
                      <!-- S T E P S -->
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
                <!-- stepper fin -->
                <!-- RESUMEN  NUEVO USUARIO  ADMINISTRATIVO -->
                <v-col>
                  <v-card>
                    <v-card-title primary-title>
                      Resumen de nuevo usuario
                    </v-card-title>
                    <v-card-text>
                      <div>Usuario: {{ nuevoUsuario.alias }}</div>
                      <div>Código: {{ nuevoUsuario.codigo }}</div>
                      <div>Nombre: {{ nuevoUsuario.nombre_completo }}</div>
                      <div></div>
                      <v-spacer></v-spacer>
                      <div v-if="nuevoUsuario.sesion_rol == 1">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle>Módulos</v-list-item-subtitle>
                            <v-list-item-title
                              v-for="modulo in nuevoUsuarioAdministradorModulos"
                              :key="modulo.id"
                              >{{ modulo.titulo }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                      <v-spacer></v-spacer>
                      <div v-if="nuevoUsuario.sesion_rol == 1">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle
                              >Supervisoras</v-list-item-subtitle
                            >
                            <v-list-item-title
                              v-for="supervisora in nuevoUsuarioAdministradorSupervisoras"
                              :key="supervisora.id"
                              >{{
                                supervisora.nombre_completo
                              }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- RESUMEN  NUEVO USUARIO  ADMINISTRATIVO -->
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :disabled="e1 > 2 || switchMe == !false"
                color="primary"
                @click="fnNextStep()"
              >
                siguiente
              </v-btn>

              <v-btn :disabled="e1 == 1" text @click="fnBackStep()">
                Atras
              </v-btn>
              <v-spacer></v-spacer>
              <!-- <v-btn color="green darken-1" text @click="dialogCreate = false">
                Disagree
              </v-btn> -->

              <v-btn color="red darken-1" text @click="dialogCreate = false">
                Cancelar
              </v-btn>
              <v-btn
                color="green"
                dark
                class="float-right"
                @click="fnCrearUsuario"
              >
                Crear nuevo usuario
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <!-- FIN Botón verde, activa modal de: crear nuevo usuario -->
      <!-- TABLA PARA GESTIÓN DE USUARIOS tbl_gestion_usr -->
      <v-col cols="12">
        <v-data-table
          disable-pagination
          :options.sync="options"
          :hide-default-footer="true"
          :headers="headers"
          :items="tbl_usuarios"
          :item-key="tbl_usuarios.id"
          class="elevation-3"
        >
          sesion_rol
          <!-- sesion_rol en tabla -->
          <template v-slot:[`item.sesion_rol`]="{ item }">
            <span v-if="item.sesion_rol == 1">
              Administrativo (parametrizado)
            </span>
            <span v-if="item.sesion_rol == 0">
              Superusuario (sin restricciones)
            </span>
          </template>
          <!-- sesion_rol en tabla -->
          <!-- fecha en tabla -->
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ getDate(item.createdAt) }}
          </template>
          <!-- fecha en tabla -->
          <!-- acciones editar modulos -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              :disabled="item.estado == 'Activo' ? false : true"
              class="align-center"
              @click="fnOpenDialog_EditarUsuario(item.id)"
            >
              mdi-cog
            </v-icon>

            <v-icon
              title="dar de baja"
              small
              :disabled="item.estado == 'Activo' ? false : true"
              class="align-center"
              @click="(dialogDelete = true), (deleteItem = item)"
            >
              mdi-delete
            </v-icon>
            <v-icon
              small
              class="align-center ml-1"
              color="primary"
              @click="fn_openDialog_logDeActualizaciones(item)"
              title="Historial de cambios de contraseña"
            >
              mdi-math-log
            </v-icon>

            <!-- <v-icon small @click="dialogDelete = true"> mdi-delete </v-icon> -->
          </template>
          <!-- acciones editar modulos -->
        </v-data-table>
      </v-col>
      <v-col>
        <v-pagination
          class="float-right"
          v-model="page"
          :length="totalPages"
          total-visible="7"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          @input="handlePageChange"
        ></v-pagination>
      </v-col>
      <!-- FIN TABLA PARA GESTIÓN DE USUARIOS tbl_gestion_usr -->
    </v-row>
    <!-- /** sección de DIALOGS */ -->
    <!-- /** FIN Botón, en tabla de usuarios para dialog para editar usuario */ -->
    <dialogEditarUsuarioAdministrativo
      id="dialogEditarUsuario"
      :flag_editar_usuario="flag_editar_usuario"
      :dialogEdit="dialogEdit"
      @fndialogEdit="(dialogEdit = false), (flag_editar_usuario = null)"
    />

    <!-- /** FIN Botón, en tabla de usuarios para dialog para editar usuario */ -->
    <!-- /** dialog para dar de baja a usuario */ -->
    <v-dialog
      id="dialogEliinarUsuario"
      scrollable
      v-model="dialogDelete"
      max-width="50%"
    >
      <v-card v-if="deleteItem" class="d-flex justify-space-between">
        <v-card-title class="text-h5"
          >¿Esta seguro de dar de baja al usuario "{{
            deleteItem.nombre_completo
          }}
          ({{ deleteItem.alias }})"? <br />
        </v-card-title>
        <v-card-text>
          <p>El proceso de dar de baja a un usuario es irreversible.</p>
          <ul>
            <li>
              El usuario ya no tendrá acceso a la información dentro del panel
            </li>
            <li>El usuario no se podrá volver a dar de alta</li>
            <li>El usuario no se eliminará de la base de datos</li>
            <li>Todas las opciones de configuración se desabilitarán</li>
          </ul>
          <v-checkbox
            label="De acuerdo"
            v-model="deAcuerdo_darDeBaja"
            value="value"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialogDelete = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="green darken-1 "
            :disabled="!deAcuerdo_darDeBaja"
            text
            @click="fnDarDeBajaAUsuario(deleteItem.id)"
            >Si, dar de baja</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** dialog para dar de baja a usuario */ -->
    <!-- agregar vinculacion de dispositivo" -->
    <usuarioHistorialCambiosContrasenia
      :dialog_log_flag="dialog_log_flag"
      :dialog_log_item="dialog_log_item"
      @fndialogEdit="(dialog_log_flag = false), (dialog_log_item = null)"
    />
    <!-- fin agregar vinculacion de dispositivo" -->
    <!-- /** DIALOGS */ -->
  </v-container>
</template>
<script>
import moment from 'moment';
require('moment/locale/es');
import { mapActions } from 'vuex';
import listaDeModulos from './usuariosGestionModulos.vue';
import listadoSupervisoras from './usuariosGestionSupervisoras.vue';
/** formulario para edición de usuario: */
import dialogEditarUsuarioAdministrativo from './dialog_editar_administrativo.vue';
import usuarioHistorialCambiosContrasenia from './dialog_usuarioHistorialCambiosContrasenia.vue';

export default {
  name: 'tbl_gestion_usr',
  components: {
    listaDeModulos,
    listadoSupervisoras,
    dialogEditarUsuarioAdministrativo,
    usuarioHistorialCambiosContrasenia,
  },
  data() {
    return {
      // historial cambios de contraseña
      dialog_log_item: null,
      dialog_log_flag: false,
      /** */
      lock_nuevoUsuarioAdministradorModulos: [],
      lock_nuevoUsuarioAdministradorSupervisoras: [],
      deAcuerdo_darDeBaja: false,
      deleteItem: null,
      //
      switchMe: null,
      loading_flag_switchMe: false,
      flag_editar_usuario: null,
      flag_crear_usuario_modulos: false,
      flag_crear_usuario_supervisoras: false,
      nuevoUsuario: {
        alias: '',
        codigo: '',
        password: '',
        nombre_completo: '',
        sesion_rol: 1,
        estado: 'Activo',
      },
      nuevoUsuarioAdministradorModulos: [],
      nuevoUsuarioAdministradorSupervisoras: [],
      //
      e1: 1,
      //
      dialogCreate: false,
      dialogEdit: false,
      dialogDelete: false,
      //
      param_fechas: [
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      ],
      //
      searchSupervisora: '',
      headers: [
        {
          text: 'Usuario',
          align: 'start',
          sortable: false,
          value: 'alias',
        },
        { text: 'Nombre y apellido', value: 'nombre_completo' },
        { text: 'Tipo', value: 'sesion_rol' },

        { text: 'Creado', value: 'createdAt' },
        { text: 'Configuraciones', value: 'actions', align: 'center' },
      ],
      tbl_usuarios: [],
      /** */
      options: {},
      page: 1,
      totalPages: 0,
      pageSize: 5,
      pageSizes: [5, 15, 50],
      /** */
    };
  },
  methods: {
    /** API FEATHERS */
    ...mapActions('Usuarios', {
      getUsuarios: 'get',
      findUsuarios: 'find',
      patchUsuario: 'patch',
      crearUsuario: 'create',
    }),
    ...mapActions('AdministrativoSupervisoras', {
      getAdministrativoSupervisoras: 'get',
      findAdministrativoSupervisoras: 'find',
      createAdministrativoSupervisoras: 'create',
    }),
    ...mapActions('AdministrativoModulos', {
      getAdministrativoModulos: 'get',
      findAdministrativoModulos: 'find',
      createAdministrativoModulos: 'create',
    }),
    //
    fnDarDeBajaAUsuario(id) {
      console.log(id);

      let debajaby =
        this.$store.state.auth.payload.usuario.id +
        ';' +
        this.$store.state.auth.payload.usuario.nombre_completo;

      this.patchUsuario([
        id,
        {
          estado: 'De Baja',
          de_baja_by: debajaby,
          de_baja_fecha: moment().format('YYYY-MM-DD HH:mm:ss:SSS +00:00'),
        },
        {},
      ])
        .then(() => {
          this.$notify({
            type: 'success',
            group: 'foo',
            title: 'El usuario se ha dado de baja',
            text: '',
          });
          //reset vars
          this.deleteItem = null;
          this.dialogDelete = false;
          this.deAcuerdo_darDeBaja = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //
    fnSwitchMe() {},
    /** FUNCIONES PROPIAS DE LA VISTA */
    getColor(calories) {
      if (calories > 400) return 'red';
      else if (calories > 200) return 'orange';
      else return 'green';
    },
    getDate(date) {
      return date ? moment(date).format('DD MMMM YYYY') : '';
    },
    fnNextStep() {
      this.e1 = this.e1 + 1;
    },
    fnBackStep() {
      this.e1 = this.e1 - 1;
    },
    fnUpdateNewModulos(value) {
      this.nuevoUsuarioAdministradorModulos = value;
    },
    fnUpdateNewSupervisoras(value) {
      this.nuevoUsuarioAdministradorSupervisoras = value;
    },
    fnResetCreateFormAfterCreate() {
      if (
        this.flag_crear_usuario_supervisoras == true &&
        this.flag_crear_usuario_modulos == true
      ) {
        this.dialogCreate = false;
        this.e1 = 1;
        this.nuevoUsuario.alias = '';
        this.nuevoUsuario.codigo = '';
        this.nuevoUsuario.password = '';
        this.nuevoUsuario.nombre_completo = '';
        this.nuevoUsuario.sesion_rol = 1;
        this.nuevoUsuarioAdministradorModulos = [];
        this.nuevoUsuarioAdministradorSupervisoras = [];
        this.dialogCreate = false;
        this.flag_crear_usuario_modulos = false;
        this.flag_crear_usuario_supervisoras = false;
      }
    },
    fnCrearUsuario() {
      this.$store.state.globalLoading = true;

      if (this.nuevoUsuario.alias == '') {
        this.e1 = 1;
        this.$store.state.globalLoading = false;
        this.$notify({
          type: 'error',
          group: 'foo',
          title: 'Agrega un nombre de usuario',
          text: '',
        });

        this.$refs.nombre_usuario.focus();

        return;
      }

      if (this.nuevoUsuario.password == '') {
        this.e1 = 1;
        this.$store.state.globalLoading = false;
        this.$notify({
          type: 'error',
          group: 'foo',
          title: 'Agrega una contraseña al usuario',
          text: '',
        });

        this.$refs.password.focus();

        return;
      }

      if (this.nuevoUsuario.sesion_rol == 1) {
        if (this.nuevoUsuarioAdministradorModulos.length <= 0) {
          this.$store.state.globalLoading = false;
          this.$notify({
            type: 'error',
            group: 'foo',
            title: 'El usuario es de accesos restringidos',
            text: 'Agrega al menos un modulo al cual pueda tener acceso',
          });

          this.e1 = 2;
          return;
        } else {
          // prettier-ignore
          this.lock_nuevoUsuarioAdministradorModulos = this.nuevoUsuarioAdministradorModulos;
        }

        if (this.nuevoUsuarioAdministradorSupervisoras.length <= 0) {
          this.$store.state.globalLoading = false;
          this.$notify({
            type: 'error',
            group: 'foo',
            title: 'El usuario es de accesos restringidos',
            text: 'Indica el acceso a las supervisoras a las cuales puede tener acceso',
          });

          this.e1 = 3;
          return;
        } else {
          // prettier-ignore
          this.lock_nuevoUsuarioAdministradorSupervisoras = this.nuevoUsuarioAdministradorSupervisoras;
        }
      }
      /** crear usuairo */
      this.crearUsuario(this.nuevoUsuario)
        .then((result) => {
          this.fnCrearUsuarioModulos(result.id);
          this.fnCrearUsuarioSupervisoras(result.id);
          this.$notify({
            type: 'success',
            group: 'foo',
            title: 'Nuevo usuario',
            text: 'Se ha creado el nuevo usuario',
          });

          /**refresh table */
          this.findUsuarios({
            query: { $or: [{ sesion_rol: 1 }, { sesion_rol: 0 }] },
          }).then((result) => {
            this.tbl_usuarios = result.data;
          });
        })
        .catch((e) => {
          console.log(e);
          this.$store.state.globalLoading = false;
          this.$notify({
            type: 'error',
            group: 'foo',
            title: 'Error al crear usuario',
            text: 'El nombre de usuario y código no se pueden repetir',
          });
        });
    },
    fnCrearUsuarioModulos(id_usuario) {
      let arregloDeModulosParaElUsuario = [];
      this.lock_nuevoUsuarioAdministradorModulos.forEach((modulo) => {
        arregloDeModulosParaElUsuario.push({
          id_usuario_administrativo: id_usuario,
          id_modulo: modulo.id,
        });
      });

      this.createAdministrativoModulos([arregloDeModulosParaElUsuario, {}])
        .then(() => {
          this.$notify({
            type: 'success',
            group: 'foo',
            title: 'Asignación de modulos',
            text: 'Se han asignado los modulos al usuario.',
          });
          //
          this.flag_crear_usuario_modulos = true;
          this.fnResetCreateFormAfterCreate();
        })
        .catch((e) => {
          this.$store.state.globalLoading = false;
          this.$notify({
            type: 'error',
            group: 'foo',
            title: 'Error al asignar modulos',
            text: e.message,
          });
        });
    },
    fnCrearUsuarioSupervisoras(id_usuario) {
      let arregloDeSupervisorasParaElUsuario = [];

      this.lock_nuevoUsuarioAdministradorSupervisoras.forEach((supervisora) => {
        arregloDeSupervisorasParaElUsuario.push({
          id_usuario_administrativo: id_usuario,
          id_usuario_supervisora: supervisora.id,
        });
      });

      this.createAdministrativoSupervisoras([
        arregloDeSupervisorasParaElUsuario,
        {},
      ])
        .then(() => {
          this.$store.state.globalLoading = false;
          this.$notify({
            type: 'success',
            group: 'foo',
            title: 'Asignación de supervisoras',
            text: 'Se han asignado las supervisoras al usuario.',
          });
          //
          this.flag_crear_usuario_supervisoras = true;
          this.fnResetCreateFormAfterCreate();
        })
        .catch((e) => {
          this.$store.state.globalLoading = false;
          this.$notify({
            type: 'error',
            group: 'foo',
            title: 'Error al asignar supervisoras',
            text: e.message,
          });
        });
    },
    fnOpenDialog_EditarUsuario(idUsuario) {
      this.dialogEdit = true;
      this.flag_editar_usuario = idUsuario;
    },
    handlePageChange(value) {
      this.page = value;
      this.fnFindUsuarios();
    },
    getParams() {
      const params = {};

      params['$or'] = [
        {
          sesion_rol: 1,
        },
        {
          sesion_rol: 0,
        },
      ];

      if (this.searchSupervisora != '') {
        params['$or'] = [
          {
            alias: { $like: '%' + this.searchSupervisora + '%' },
          },
          {
            nombre_completo: { $like: '%' + this.searchSupervisora + '%' },
          },
        ];
      }

      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;

      return params;
    },
    fnFindUsuarios() {
      this.findUsuarios({ query: this.getParams() }).then((result) => {
        this.tbl_usuarios = result.data;
        this.totalPages = Math.ceil(
          parseInt(result.total) / parseInt(this.pageSize)
        );
      });
    },
    /** agregar modulos a usuario de tipo "agente de comercio" */
    async fn_openDialog_logDeActualizaciones(usuario) {
      this.dialog_log_flag = true;
      this.dialog_log_item = await this.getUsuarios(usuario.id);
    },
  },
  mounted() {
    console.log(moment().format('YYYY-MM-DD HH:mm:ss:SSS Z'));
    this.fnFindUsuarios();
  },
  watch: {
    searchSupervisora(newValue) {
      if (newValue == '') {
        this.fnFindUsuarios();
      }
    },
    switchMe(newValue) {
      if (newValue == true) {
        this.nuevoUsuario.sesion_rol = 0;
      } else {
        this.nuevoUsuario.sesion_rol = 1;
      }
    },
  },
};
</script>
<style scoped>
.card-modulos {
  display: flex !important;
  flex-direction: column;
}

.card-text-modulos {
  flex-grow: 1;
  overflow: auto;
}
</style>
